<template>
  <div>
    <nav class="main-header navbar navbar-expand navbar-white navbar-light">
      <!-- Left navbar links -->
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" data-widget="pushmenu" href="#"><i class="fas fa-bars"></i></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#" @click.prevent="cashClear">
            <i class="fa fa-brush"></i>
            <slot v-if="loader">
                <div class="ml-1 spinner-border spinner-border-sm text-success" role="status"></div>
            </slot>
          </a>
        </li>
        <li class="nav-item" v-if="authUser.role_id == 1 || authUser.role_id == 2 || authUser.role_id == 4">
          <a class="nav-link" href="https://bulksmsbd.net/" target="_blank">
            SMS Balance: <b>{{ smsBalance }} </b> Tk. <button v-if="smsBalance <= 1000" style="color:red">Click to Recharge </button>
          </a>
        </li>
      </ul>
      <!-- Right navbar links -->
      <ul class="navbar-nav ml-auto">        
        <li class="nav-item dropdown">
          <select v-model="locale" @change="handleChange($event)" class="custom-select">
            <option value="en" :selected="enActive">En</option>
            <option value="bn" :selected="bnActive">Bn</option>
          </select>
        </li>
        <li class="nav-item dropdown" title="Merchant Request">
          <a class="nav-link" data-toggle="dropdown" href="#" aria-expanded="false">
            <i class="far fa-comments"></i>
            <span class="badge badge-danger navbar-badge">{{ merchantList.length > 0 ? merchantList.length : '' }}</span>
          </a>
          <div  v-if="merchantList.length > 0" class="dropdown-menu dropdown-menu-lg dropdown-menu-right long-dropdown" style="left: inherit; right: 0px;">
            <div v-for="(merchant, mrchant) in merchantList" :key="mrchant">
              <router-link :to="'/merchant?id='+ merchant.id" class="dropdown-item">
                <div class="media">
                  <div class="media-body">
                    <h4 class="dropdown-item-title">
                      {{ merchant.business }} - {{ merchant.mobile }}
                    </h4>
                    <p class="text-sm"></p>
                  </div>
                </div>
              </router-link>
              <div class="dropdown-divider"></div>
            </div>
            <router-link :to="'merchant'" class="dropdown-item dropdown-footer text-center">Show All</router-link>
          </div>
        </li>
        <li class="nav-item dropdown" title="Pickup Request">
          <a class="nav-link" data-toggle="dropdown" href="#" aria-expanded="false">
            <i class="fa fa-truck"></i>
            <span class="badge badge-danger navbar-badge">{{ pickupRequestList.length > 0 ? pickupRequestList.length : '' }}</span>
          </a>
          <div v-if="pickupRequestList.length > 0" class="dropdown-menu dropdown-menu-lg dropdown-menu-right long-dropdown" style="left: inherit; right: 0px;">
            <div v-for="(pickup, pick) in pickupRequestList" :key="pick">
              <router-link :to="'/pickup?id='+ pickup.id" class="dropdown-item">
                <div class="media">
                  <div class="media-body">
                    <h4 class="dropdown-item-title">
                      {{ pickup.merchant.business }}
                    </h4>
                    <p class="text-sm"></p>
                  </div>
                </div>
              </router-link>
              <div class="dropdown-divider"></div>
            </div>
            <router-link :to="'pickup'" class="dropdown-item dropdown-footer text-center">Show All</router-link>
          </div>
        </li>
        <li class="nav-item dropdown" title="Payment Request">
          <a class="nav-link" data-toggle="dropdown" href="#" aria-expanded="false">
            <i class="fa fa-dollar-sign"></i>
            <span class="badge badge-danger navbar-badge">{{ paymentRequestList.length > 0 ? paymentRequestList.length : '' }}</span>
          </a>
          <div v-if="paymentRequestList.length > 0" class="dropdown-menu dropdown-menu-lg dropdown-menu-right long-dropdown" style="left: inherit; right: 0px;">           
            <div v-for="(item, index) in paymentRequestList" :key="index">
              <router-link :to="'/payment-request?id='+ item.id" class="dropdown-item">
                <div class="media">
                  <div class="media-body">
                    <h4 class="dropdown-item-title">
                      {{ item.merchant.business }}
                    </h4>
                    <p class="text-sm"></p>
                  </div>
                </div>
              </router-link>
              <div class="dropdown-divider"></div>
            </div>
            <router-link :to="'payment-request'" class="dropdown-item dropdown-footer text-center">Show All</router-link>
          </div>
        </li>
        <li class="nav-item dropdown" title="Issue Request">
          <a class="nav-link" data-toggle="dropdown" href="#" aria-expanded="false">
            <i class="fa fa-question"></i>
            <span class="badge badge-danger navbar-badge">{{ issues.length > 0 ? issues.length : '' }}</span>
          </a>
          <div v-if="issues.length > 0" class="dropdown-menu dropdown-menu-lg dropdown-menu-right long-dropdown" style="left: inherit; right: 0px;">
            <div v-for="(issue, index) in issues" :key="index">
              <router-link :to="'/issue-order?id='+ issue.order_id" class="dropdown-item">
                <div class="media">
                  <div class="media-body">
                    <h4 class="dropdown-item-title">
                      {{ issue.business }} - {{ issue.sku }}
                    </h4>
                    <p class="text-sm"></p>
                  </div>
                </div>
              </router-link>
              <div class="dropdown-divider"></div>
            </div>
            <router-link :to="'issue-order'" class="dropdown-item dropdown-footer text-center">Show All</router-link>
          </div>
        </li>        
        <li class="nav-item dropdown">
          <a class="nav-link" data-toggle="dropdown" href="#" aria-expanded="false">
            <i class="fa fa-power-off"></i>
          </a>
          <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right" style="left: inherit; right: 0px;">
            <router-link :to="'edit-user'" class="dropdown-item dropdown-footer"><i class="fas fa-user-edit mr-2"></i> Edit Profile</router-link>
            <a href="#"  @click.prevent="logout" class="dropdown-item dropdown-footer"><i class="fas fa-sign-out-alt mr-2"></i> Sign Out</a>
          </div>
        </li>
      </ul>
    </nav>
  </div>
</template>
<script>
import config from '@/config'
import axios from 'axios'
export default {
  name:'Navbar',   
  data () {
    return {
      loader: false,
      locale: 'en',
      smsBalance: 0
    }
  },
  created () {
    this.locale = this.currentLang
    this.getMerchantRequest()
    this.getPickupRequest()
    this.getPaymentRequest()
    this.getIssueRequest()
    if (this.authUser.role_id == 1 || this.authUser.role_id == 2 || this.authUser.role_id == 4) {
      this.getSmsBalance()
    }
  },
  computed : {
    bnActive: function () {
      return this.currentLang == 'bn' ? true : false
    },
    enActive: function () {
      return this.currentLang == 'en' ? true : false
    },
    currentLang: function () {
      return this.$store.state.language
    },
    merchantList: function () {
      return this.$store.state.pendingMerchantList
    },
    paymentRequestList: function () {
      return this.$store.state.paymentRequestList
    },
    pickupRequestList: function () {
      return this.$store.state.pickupRequestList
    },
    issues: function () {
      return this.$store.state.issueRequestList
    },
    authUser: function () {
      return this.$store.state.authUser
    }
  },
  methods:{
    handleChange (event) {
      const localeVal = event.target.value      
      this.$i18n.locale = localeVal
      this.locale = event.target.value
      this.$store.dispatch('setLang', localeVal)      
    },
    async getMerchantRequest () {
      const response = await config.getData('/dashboard/merchant-request')
      if (response.status === 200) {
        this.$store.dispatch('setNavbarMerchant', response.data.merchants)
      } else {
        this.$store.dispatch('setNavbarMerchant', [])
      }  
    },
    async getPickupRequest () {
      const params = { hub_id: this.authUser.role_id == 7 ? this.authUser.hub_id : 0 }
      const response = await config.getData('/dashboard/pickup-request', params)
      if (response.status === 200) {
        this.$store.dispatch('setNavbarPickup', response.data.pickups)
      } else {
        this.$store.dispatch('setNavbarPickup', [])
      }  
    },
    async getPaymentRequest () {
      const response = await config.getData('/dashboard/payment-request')
      if (response.status === 200) {
        this.$store.dispatch('setNavbarPayment', response.data.payment_requests)
      } else {
        this.$store.dispatch('setNavbarPayment', [])
      }  
    },
    async getIssueRequest () {
      const params = { hub_id: 1 }
      const response = await config.getData('/dashboard/issue-request', params)
      if (response.status === 200) {
        this.$store.dispatch('setNavbarIssue', response.data.issues)
      } else {
        this.$store.dispatch('setNavbarIssue', [])
      }  
    },
    async getSmsBalance () {
      const response = await axios.get('https://bulksmsbd.net/api/getBalanceApi?api_key=ifkqmnxO1oBzRNQblwTl')
      if (response.data.response_code == 202) {
        this.smsBalance = response.data.balance.toFixed(2);
        if (this.smsBalance <= 300) {
          this.smsServiceoff();
        }
      } else {
        this.smsBalance = 0
      }
    },
    async smsServiceoff () {
      await config.getData('/profile/sms-service-off')
    },
    async cashClear() {
      this.loader = true
      await config.getData('/clear-cache')
      this.loader = false    
    },
    logout(){
      config.postData('/admin/logout')
      .then(() => {
        this.$store.dispatch('setAuthUser', '')        
        // this.$store.dispatch('setRoleStatusList', [])
        localStorage.removeItem('accessToken')   
        this.$router.push('/auth/login');
      })      
    }
  }
}
</script>

<style scoped>
  .navbar-badge {
    font-size: .6rem;
    font-weight: 300;
    padding: 2px 4px;
    position: absolute;
    right: 5px;
    top: 9px;
  }
  .navbar-expand .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .font-red{
    color: red;
  }
  .font-black {
    color: black;
  }
  .long-dropdown {
    margin: 4px, 4px;
    padding: 4px;
    width: 500px;
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: justify;
  }
</style>