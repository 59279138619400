<template>
  <div>
    <aside class="main-sidebar sidebar-dark-primary elevation-4">
      <a data-widget="pushmenu" href="#" class="nav-link show-on-desktop text-right"><i class="fas fa-arrow-left"></i></a>
      <!-- Brand Logo -->
      <a hre="javascript;" @click="checkRoleUserAndRouterGo" class="brand-link">
        <div class="login-logo"><img :src="'./images/logo.png'"/></div>
      </a>
      <!-- Sidebar -->
      <div class="sidebar">
        <div class="user-panel mt-3 pb-3 mb-3 d-flex">
          <div class="image">
             <!-- <slot v-if="authUser.image">
              <img :src="$image_path + authUser.image" class="img-circle" style="width:60px; height: 40px;"> 
            </slot> -->
             <!-- <slot v-else> -->
              <img :src="'./images/avatar.png'" class="img-circle elevation-2" alt="User Image">
            <!-- </slot> -->
          </div>
          <div class="info">
            <a href="#" class="d-block">{{ authUser.name }}</a>
          </div>
        </div>
        <nav class="mt-3">
          <ul class="nav nav-pills nav-sidebar flex-column nav-flat" data-widget="treeview" role="menu" data-accordion="false">
            <li class="nav-item" v-for="(menu, index) in menuList" :key="index">
              <router-link :to="menu.url" class="nav-link">
                <i class="far fa-circle nav-icon"></i>
                <p>{{ locale == 'bn' ? menu.name_bn : menu.name }}</p>
              </router-link>
            </li>
            <li class="nav-item has-treeview" v-for="(menuModule, indx) in moduleMenuList" :key="'D' + indx + 1" :class="indx+1 == subMenuKey && subMenu ? 'menu-open' : ''"
              @click="checkSubmenu(indx+1)">
              <router-link to="" class="nav-link">
                <i class="nav-icon far fa-plus-square"></i> 
                <p>
                  {{ locale == 'bn' ? menuModule.module_name_bn : menuModule.module_name }}
                  <i class="fas fa-angle-left right"></i>
                </p>
              </router-link>
              <ul class="nav nav-treeview">
                <li class="nav-item" v-for="(tmpMenu, key) in menuModule.menus" :key="indx+1+key">
                  <router-link :to="tmpMenu.url" class="nav-link">
                    <i class="far fa-circle text-yellow nav-icon"></i>
                    <p>{{ locale == 'bn' ? tmpMenu.name_bn : tmpMenu.name }}</p>
                  </router-link>
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <a href="#" class="nav-link" @click.prevent="logout">
                <i class="nav-icon fas fa-sign-out-alt"></i>
                <p>Sign Out</p>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  </div>
</template>
<script>
import config from '../../config'
export default {
  name:'Sidebar',
  data(){
    return {
      subMenuKey: 0,
      subMenu: false,
      moduleMenu: false,
      inactivityTimeout: null,
      maxInactivityTime: 15 * 60 * 1000, // 15 minutes in milliseconds
    }
  },
  computed : {
    menuList: function () {
      return this.$store.state.menus.commonMenus
    },
    moduleMenuList: function () {
      return this.$store.state.menus.moduleMenus
    },
    loading: function () {
      return this.$store.state.loading
    },
    locale () {
      return this.$store.state.language
    },
    authUser () {
      return this.$store.state.authUser
    }
  },
  // created () {  
  //   // this.getPermission()

  //   // Set up initial timer
  //   this.resetInactivityTimer();
  //   // Listen for user activity events
  //   window.addEventListener('mousemove', this.resetInactivityTimer);
  //   window.addEventListener('keydown', this.resetInactivityTimer);
  // },
  // beforeDestroy() {
  //   // Clean up event listeners
  //   window.removeEventListener('mousemove', this.resetInactivityTimer);
  //   window.removeEventListener('keydown', this.resetInactivityTimer);
  //   clearTimeout(this.inactivityTimeout);
  // },
  methods: {
    checkSubmenu (key) {
      this.subMenuKey = key
      this.subMenu = this.subMenu ? false : (this.subMenuKey == key ? true : false)
    },
    getPermission() {
      this.$store.dispatch('loadingState',  true)
      config.getData("/user/permissions")
      .then((response) => {
        if (response.status == 200) {
          this.$store.dispatch('loadingState',  false)
          window.Permissions = response.data;
        }
      })  
    },
    checkRoleUserAndRouterGo () {
      const roleId = this.authUser.role_id
      if (roleId == 1 || roleId == 6) {
        this.$router.push('/')
      } else if (roleId == 7) {
        this.$router.push('/hub-dashboard')
      } else {
        this.$router.push('/operational-dashboard')
      }
    },
    resetInactivityTimer() {
      clearTimeout(this.inactivityTimeout);
      this.inactivityTimeout = setTimeout(this.logout, this.maxInactivityTime);
    },
    logout(){
      this.$router.push('/auth/login');
      config.postData('/admin/logout')
      .then(() => { 
        this.$store.dispatch('setMenu', [])
        // this.$store.dispatch('setRoleStatusList', [])
        this.$store.dispatch('setAuthUser', '')
        localStorage.removeItem('accessToken');    
      })      
    }
  }
}
</script>
<style scoped>
.show-on-desktop {
  display: none;
}
@media screen and (max-width: 480px) {
    .show-on-desktop {
      display: block;
    }
}
</style>
